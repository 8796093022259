import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"

const Pathways = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "pathways-image.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      graphImage: file(relativePath: { eq: "bodhi-graph.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Pathways" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>Pathways</h1>
      </BackgroundImage>
      <div
        id="features"
        className="about__features bodhi__bg-pattern margin-header"
      >
        <div className="container">
          <div className="feature_wrapper option2">
            <div className="row">
              <div className="col-sm-12">
                <h2>
                  Bodhi Offers a Guaranteed Pathway to the Bachelor of Teaching
                  (ECE) and Teacher Registration.
                </h2>

                <p>
                  Kim & Kerry have been in the ECE industry for many years and
                  know that being recognised as a qualified ECE educator is
                  something that needs to be achievable and therefore have safe
                  guarded pathway options to all of our students.
                </p>
                <p>
                  We have created some great partnerships with other providers
                  in the industry who do offer Bachelor programmes, these are
                  known as Initial Teacher Education (ITE) providers, and upon
                  completion of the level 6 Diploma our students can complete
                  their final year of the Bachelor with Te Rito Maioha. Together
                  we have ensured that our programmes are capatible and that all
                  the ITE requirements are being met. Bodhi and Te Rito Maioha
                  share the same vision and aspirations for our students.
                </p>
                <p>
                  What this all means is that you can start your journey into
                  becoming an ECE teacher within small cohorts, designated
                  tutors which allows for students to have a more personable
                  approach and loads of support when studying online. Another
                  added bonus of studying in a progressive manner is it allows
                  for your academic skills to increase organically over time.
                </p>
                <p>
                  Bodhi graduates of the Level 5 & 6 programmes are also
                  eligible for an exemption of the IELTS requirements when
                  applying for the cross credit into the Bachelor of Teaching
                  programmes.
                </p>

                <a
                  href="https://teachingcouncil.nz/getting-certificated/for-overseas-trained-teachers/language-
competency-requirements/language-competency-exemptions-
expanded/#:~:text=If%20an%20applicant%20for%20entry,%2Dby%2Dcase%20discretionary
%20basis"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Further info on this here
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Img
                  fluid={data.graphImage.childImageSharp.fluid}
                  alt="Bodhi Pathways Graph"
                />
                <h3>
                  <b>
                    <i>
                      Ehara taku toa i te toa takitahi Engari, he toa takitini
                    </i>
                    <br /> My successes are not mine alone, they are ours - the
                    greatest successes we will have are from working together.
                  </b>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Pathways
